<template>
  <div class="slot-container">
    <!--    <div class="hidden-sm-and-down">-->
    <!--        <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">-->
    <!--            <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">-->
    <!--                <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)"  :aspect-ratio="1920/467"></v-img>-->
    <!--            </v-carousel-item>-->
    <!--        </v-carousel>-->
    <!--    </div>-->

    <v-img v-if="$vuetify.breakpoint.xsOnly" class="align-end" :aspect-ratio="360 / 140" :src="`${mediaUrl}/game_banner/${providerSelected}.jpg`">
      <v-card-title class="white--text font-weight-bold pa-0">
        <p class="mb-0 ml-3">
          <span class="text-capitalize">
            {{ $t(`label.${providerSelected}`) }}
          </span>
        </p>
      </v-card-title>
    </v-img>
    <v-img v-else class="align-end" :aspect-ratio="1800 / 200" :src="`${mediaUrl}/game_banner/${providerSelected}.jpg`">
      <v-card-title class="white--text font-weight-bold">
        <p class="ml-3">
          <span class="text-capitalize">
            {{ $t(`label.${providerSelected}`) }}
          </span>
        </p>
      </v-card-title>
    </v-img>

    <!-- <div class="game-filter-display">
                <v-row no-gutters>
                  <v-col cols="auto" align-self="center">
                    <p class="game-filter-display-text">
                      {{ $t(`label.show`) }}:
                      {{
                        searchCriteria.provider == '' || searchCriteria.provider == undefined ||
                        searchCriteria.provider == 'all' ? $t(`label.all`) :
                            $t(`gameVendor.${searchCriteria.provider}_short`)
                      }}{{ $t(`label.slotGames`) }}
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="3" align-self="center">
                    <v-btn class="game-filter-button"
                          @click="gameFilterSheet = true">
                      <nav-filter class="icon_filter"></nav-filter>
                      {{ $t(`label.filter`) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div> -->

    <div class="search-container" v-if="!isTransferWallet">
      <div no-gutters class="search-wrapper">
        <v-combobox
          autocomplete="off"
          class="search_game pl-0"
          light
          hide-details
          dense
          solo
          filled
          :items="searchableGameList"
          :clearable="true"
          :label="$t('label.search_game')"
          :prepend-icon="'search'"
          @click:append="searchGame()"
          @click:clear="searchGame()"
          @change="searchGame()"
          item-text="name"
          v-model.trim="searchValue"
          return-object
        ></v-combobox>
      </div>
    </div>

    <div class="game-vendor-container" v-if="storageGameProviderType && storageGameProviderType.find(x => x.type == providerSelected)">
      <div class="game-vendor-wrapper">
        <div class="game-list-container d-flex justify-start" style="margin-right: 110px">
          <div v-for="provider in storageGameProviderType.find(x => x.type == providerSelected).providers" class="vendor-list-item slot-product">
            <v-checkbox v-model="selectedProviderGroup[provider.providerCode]" @click="changeGroup" hide-details :label="stringFormat('{0}', provider.providerCode.toUpperCase())"></v-checkbox>
          </div>

          <!-- <div class="vendor-list-item slot-product"> -->
          <!-- <v-btn height="auto" :ripple="false" :class="searchCriteria.provider == '' || searchCriteria.provider == undefined || searchCriteria.provider == 'all' ? 'yellow--text' : ''" class="text-capitalize text-center font-weight-bold vendor-list-item-button" @click="goToVendor('all')"> -->
          <!-- searchCriteria.provider == '' || searchCriteria.provider == undefined || searchCriteria.provider == 'all' -->

          <!-- </div> -->

          <!-- <div class="vendor-list-item slot-product" > -->

          <!-- <v-btn height="auto" :ripple="false" @click="goToVendor(provider.providerCode)" :class="searchCriteria.provider == provider.providerCode ? 'yellow--text' : ''" class="text-capitalize text-center font-weight-bold my-0 vendor-list-item-button"> -->
          <!-- <v-btn height="auto" :ripple="false" @click="goToVendor(provider.providerCode)" class="text-capitalize text-center font-weight-bold my-0 vendor-list-item-button">
                  <v-col cols="12" class="pa-0">
                      <v-col cols="12" class="py-0 text-center">
                          <v-divider v-show="searchCriteria.provider == provider.providerCode" class="vendor-menu-divider"></v-divider>
                      </v-col>
                  </v-col>
                  <v-col cols="12" class="pa-0">

                  </v-col>
              </v-btn> -->
          <!-- </div> -->
        </div>
        <div @click="() => (moreFilter = !moreFilter)" class="more-filter">
          {{ moreFilter ? 'Hide Filters' : 'More Filters' }}
        </div>

        <div v-if="moreFilter" class="game-list-container d-flex justify-space-between">
          <div v-for="category in categories" class="vendor-list-item slot-product">
            <!-- <v-btn v-for="(category, index) in categories" :key="index" elevation="0" large text class="subtitle-2 text-capitalize" :color="selectedCategory == category.name ? 'white' : '#ACACAC'" tile @click="changeGameCategory(category.name)">
            {{ $t(`gameCategory.${category.name}`) }}
        </v-btn> -->
            <v-checkbox v-model="selectedCategoryGroup[category.name]" @click="changeGroup" :label="$t(`gameCategory.${category.name}`)" hide-details></v-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="games-card-container py-4">
      <div class="mx-auto games-card-wrapper px-2">
        <template v-for="(game, index) in gameList">
          <v-badge right overlap color="light" offset-x="30" offset-y="10">
            <template v-slot:badge class="slot-game-badge">
              <v-img class="badge-img" :src="checkCategory(game.category)"></v-img>
            </template>
            <!-- <v-avatar :size="$vuetify.breakpoint.smAndDown ? $vuetify.breakpoint.xsOnly ? '100' : '120': '150'"> -->
            <div class="slot-game-card">
              <v-img class="slot-game-image-box" :src="game.imageCdn == null ? `${mediaUrl}/game_icons/en/${game.provider}/${game.code}_${game.id}.jpg` : `${game.imageCdn}`" />
              <!-- <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
          </template> -->
              <div class="slot-game-button-container" :game-name="getGameName(game.locale)">
                <div class="slot-game-button-box" v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <!-- <v-btn icon class="slot-game-button" @click="launchGame(game, false)">
                  <v-avatar :size="$vuetify.breakpoint.mdAndDown ? '48':'60'">
                      <img src="/static/svg/play_btn.svg" @mouseover="hoverPlayButton($event.currentTarget, true)" @mouseleave="hoverPlayButton($event.currentTarget, false)" alt />
                  </v-avatar>
              </v-btn>
              <v-btn v-if="game.isDemoAvailable" icon class="slot-game-button" :class="$vuetify.breakpoint.xsOnly ? 'ml-4':'ml-8'" @click="launchGame(game, true)">
                  <v-avatar :size="$vuetify.breakpoint.mdAndDown ? '48':'60'">
                      <img src="/static/svg/demo_btn.svg" @mouseover="hoverDemoButton($event.currentTarget, true)" @mouseleave="hoverDemoButton($event.currentTarget, false)" alt />
                  </v-avatar>
              </v-btn> -->
                  <button-primary :action="() => launchGame(game, false)" :title="$t(`label.jomPlay`)" class="playBtn"></button-primary>
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)">
                    {{ $t(`label.tryDemoNow`) }}
                  </v-btn>
                </div>
                <div class="slot-game-button-box maintenance text-center" v-else>
                  <span class="mt-3 pa-2 primary--text under-maintenance-text">{{ $t(`label.underMaintenance`) }}</span>
                </div>
              </div>
              <!-- <img :src="`${mediaUrl}/rng_lobby/vendors/${game.provider}.svg`" class="vendor_small_icon"/> -->
              <!-- <v-img :src="checkCategory(game.category)"
                      :class="[checkClassCategory(game.category), {'isMobile' : $vuetify.breakpoint.mdAndDown}]"></v-img>
          </v-img> -->
              <div class="slot-game-name-box">{{ getGameName(game.locale) }}</div>
              <!-- <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="pt-2 font-weight-bold slot-game-name-box text-center elevation-0">
                      {{ getGameName(game.locale) }}
                  </div>
              </template>
              <span>{{ getGameName(game.locale) }}</span>
          </v-tooltip>  -->
            </div>
          </v-badge>

          <!--    ghost markup to push last row items align to left , 'index >= 2' mean only display if more than one row(2 items min per row)     -->
          <v-badge id="autoFillEle1" v-if="index == gameList.length - 1 && index >= 2" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
          <v-badge id="autoFillEle2" v-if="index == gameList.length - 1 && index >= 2" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
          <v-badge id="autoFillEle3" v-if="index === gameList.length - 1 && index >= 2" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
          <v-badge id="autoFillEle4" v-if="index == gameList.length - 1 && index >= 2" right overlap color="light" offset-x="30" offset-y="30" style="height: 0 !important">
            <template v-slot:badge class="slot-game-badge"></template>
            <div class="slot-game-card" style="height: 0 !important">
              <v-img />
              <div :game-name="getGameName(game.locale)">
                <div v-if="!isGameMaintenance(providerSelected, game.provider)">
                  <v-btn v-if="game.isDemoAvailable" class="mt-3 buttonWhite white--text slot-game-demo-button" @click="launchGame(game, true)"></v-btn>
                </div>
              </div>
            </div>
          </v-badge>
        </template>

        <!-- <v-bottom-sheet v-model="gameFilterSheet" persistent fullscreen scrollable>
            <v-sheet class="text-center">
                <div class="game-filter-sheet-header">
                <v-icon class="game-filter-sheet-close-icon white--text"
                        @click="gameFilterSheet = !gameFilterSheet">clear
                </v-icon>
                <p class="white--text">{{ $t(`label.filter`) }}</p>
                </div>
                <div class="game-filter-sheet-body">
                <div class="game-filter-sheet-body-content">
                    <p class="game-filter-sheet-body-content-text">{{ $t(`label.vendor`) }}</p>

                    <div class="game-filter-vendor-type-item-box">
                    <div class="game-filter-vendor-type-item">
                        <p @click="goToVendor('all')"
                        :class="searchCriteria.provider == '' || searchCriteria.provider == undefined || searchCriteria.provider == 'all' ? 'active white--text' : ''">
                        {{ $t(`label.all`) }}
                        </p>
                    </div>
                    <div v-if="storageGameProviderType">
                        <div class="game-filter-vendor-type-item"
                            v-for="provider in storageGameProviderType.find(x => x.type == 'rng').providers"
                            :key="provider.providerCode">
                        <p @click="goToVendor(provider.providerCode)"
                            :class="searchCriteria.provider == provider.providerCode ? 'active white--text' : ''">
                            {{ $t(`gameVendor.${provider.providerCode}_short`) }}
                        </p>
                        </div>
                    </div>
                    </div>
                    <p class="game-filter-sheet-body-content-text">{{ $t(`label.category`) }}</p>
                    <div class="game-filter-vendor-type-item-box">
                    <div class="game-filter-vendor-type-item" v-for="category in categories"
                        :key="category.name">
                        <p @click="changeGameCategory(category.name)"
                        :class="selectedCategory == category.name ? 'active white--text' : ''">{{
                            $t(`gameCategory.${category.name}`)
                        }}</p>
                    </div>
                    </div>
                </div>
                </div>
            </v-sheet>
            </v-bottom-sheet> -->
      </div>
    </div>
    <app-transfer-wallet-main-component v-if="isTransferWallet" :provider="this.$route.params.provider"></app-transfer-wallet-main-component>
    <p v-else-if="gameList.length <= 0" class="text-center text-capitalize mb-10">{{ $t(`label.noGameFound`) }}</p>
    <div class="text-center mb-6" v-if="pagination.totalPage > 1">
      <v-pagination
        class="game_pagination"
        v-model="pagination.currentPage"
        :length="pagination.totalPage"
        @input="changePage"
        :next-icon="'arrow_forward_ios'"
        :prev-icon="'arrow_back_ios'"
        :total-visible="pagination.paginationPageLimit"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { uiHelper,locale } from '@/util'
import { CACHE_KEY, SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import format from 'string-format'
import { GAME_TYPE_GAMES, GAME_CATEGORIES, GAME_JACKPOT_LIST } from '@/store/game.module'
import { SEMI_TRANSFER } from '@/store/transfer.module'
import { AnnouncementType } from '@/constants/enums'
import { ANNOUNCEMENT_LIST } from '@/store/announcement.module'
import _ from 'lodash'
import AppTransferWalletMainComponent from '@/components/slot/TransferWalletMainComponent'
import { formValidator } from '@/util'
import NavFilter from '@/assets/svg/navfilter.svg'
import {FOOTER_SEO} from "@/store/lookup.module";

export default {
  name: 'SlotPage',
  components: {
    AppTransferWalletMainComponent,
    NavFilter
  },
  props: {
    providerSelected: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: instance => ({
    gameNameList: [],
    validator: formValidator,
    searchValue: null,
    searchableGameList: [],
    searchedValue: '',
    isHotCls: false,
    isNewCls: false,
    filteredAnnouncement: [],
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    gameFilterSheet: false,
    stringFormat: format,
    currentLanguage: uiHelper.getLanguage(),
    moreFilter: false,
    selectedProviderGroup: {},
    selectedCategoryGroup: {},
    selectedGameName: '',
    selectedCategory: 'all',
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    gameProvider: '',
    gameName: '',
    filteredSlotGame: [],
    searchCriteria: {
      provider: '',
      pageNumber: 1,
      pageSize: 40,
      mobilePageSize: 20
    },
    gameList: [],
    categories: [],
    jackpotAmount: [],
    jackpotAmountInDigit: 0,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    storageRngGames: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + instance.providerSelected),
    storageRngJackpot: uiHelper.getLocalStorage(`${CACHE_KEY.JACKPOT}_${uiHelper.getCurrency()}`),
    routeName: ROUTE_NAME
  }),
  computed: {
    // storageGameProviderType() {
    //   return uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
    // },
    // storageRngGames() {
    //   return uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + this.providerSelected )
    // },
    // storageRngJackpot() {
    //   return uiHelper.getLocalStorage(`${CACHE_KEY.JACKPOT}_${uiHelper.getCurrency()}`)
    // },
    wallet() {
      return this.$store.state.member.walletBalance
    },
    isTransferWallet() {
      let wallet = this.$route.params.provider
      return wallet == 'kiss918' || wallet == 'mega888' || wallet == 'pussy888' || wallet == 'xe88' || wallet == 'kisskaya'
    },
    announcementList() {
      return this.$store.state.announcement.announcements
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    SlotGames() {
      return this.$store.state.game.SlotGames
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    gameCategory() {
      return this.$store.state.game.categories
    },
    jackpots() {
      return this.$store.state.game.jackpots
    },
    bannerContent() {
      return this.$store.state.cms.bannerContent
    },
    categoryGroup() {
      this.selectedCategoryGroup = this.$store.state.game.categoryGroup
      return this.$store.state.game.categoryGroup
    },
    providerGroup() {
      if (this.$route.params.provider && this.$route.params.provider.length > 0) {
        for (let provider in this.$store.state.game.providerGroup) {
          if (this.$route.params.provider == 'rng') {
            this.$store.state.game.providerGroup[provider] = true
          } else if (this.$route.params.provider == provider) {
            this.$store.state.game.providerGroup[provider] = true
          } else {
            this.$store.state.game.providerGroup[provider] = false
          }
        }
      } else {
        for (let provider in this.$store.state.game.providerGroup) {
          this.$store.state.game.providerGroup[provider] = true
        }
      }
      this.selectedProviderGroup = this.$store.state.game.providerGroup
      return this.$store.state.game.providerGroup
    }
  },
  watch: {
    wallet() {
      return this.$store.state.member.walletBalance
    },
    // storageGameProviderTypeWatch() {
    //   this.storageGameProviderType = uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
    // },
    // storageRngGamesWatch() {
    //   this.storageRngGames = uiHelper.getLocalStorage(`${CACHE_KEY.GAME_LIST}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}_` + this.providerSelected )
    // },
    // storageRngJackpotWatch() {
    //   this.storageRngJackpot = uiHelper.getLocalStorage(`${CACHE_KEY.JACKPOT}_${uiHelper.getCurrency()}`)
    // },
    announcementList() {
      this.storageAnnouncement = this.$store.state.announcement.announcements
      this.populateAnnouncement(this.storageAnnouncement)
    },
    $route() {
      this.getGame()
    },
    gameCategory() {
      let d = this.$store.state.game.categories
      // this.categories.push({
      //     id: 0,
      //     name: 'all',
      //     icon: 'grid_on'
      // })
      d.forEach(category => {
        let categoryName = category.name.toLowerCase()
        this.categories.push({
          id: category.id,
          name: categoryName,
          icon: categoryName == 'hot' ? 'whatshot' : categoryName == 'new' ? 'fiber_new' : ''
        })
      })
    },
    SlotGames() {
      this.storageRngGames = this.$store.state.game.SlotGames
      this.pagination.currentPage = 1
      this.getFilteredGame(1)
    },
    jackpots() {
      this.generateJackpot()
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    categoryGroup() {
      this.selectedCategoryGroup = this.categoryGroup
      this.getFilteredGame(1)
    },
    providerGroup() {
      this.selectedProviderGroup = this.providerGroup
      this.getFilteredGame(1)
    }
  },
  created() {
    this.getGame()
    this.getGameCategories()
    //this.getGameJackpot()
    //this.getAnnouncement()
    this.metaTag()
    //this.setFooterSEO()
  },
  methods: {
    setFooterSEO() {
      let pageObj = {
        currentRoute : this.$route.name
      }
      this.$store.dispatch(`${FOOTER_SEO}`, {pageObj})
    },
    metaTag() {
      uiHelper.setMetaTag('url', window.location.origin + '/slot/')
      uiHelper.setTitle(`${locale.getMessage('meta.slot_title')}`)
      uiHelper.setMetaTag('description', `${locale.getMessage('meta.slot_description')}`)
      uiHelper.setCanonical(window.location.origin + '/slot/')
    },
    searchGame() {
      this.getFilteredGame(1)
    },
    getAnnouncement() {
      if (this.storageAnnouncement == null) {
        let obj = {
          site: uiHelper.getPlatform(),
          type: AnnouncementType.ALL,
          page: '',
          itemsPerPage: ''
        }
        this.$store.dispatch(`${ANNOUNCEMENT_LIST}`, {
          obj
        })
      } else {
        this.populateAnnouncement(this.storageAnnouncement)
      }
    },
    populateAnnouncement(announcementData) {
      let p = []
      announcementData.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      this.filteredAnnouncement = p
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance
      }
    },
    getGame() {
      this.searchCriteria.pageNumber = 1
      this.searchCriteria.provider = this.$route.params.provider != 'all' ? this.$route.params.provider : 'all'
      if (this.storageRngGames == null) {
        let Obj = {
          currency: uiHelper.getCurrency(),
          type: this.providerSelected,
          size: '',
          page: '',
          platform: uiHelper.getPlatform(),
          category: '',
          provider: '',
          mode: 'All'
        }
        this.$store.dispatch(`${GAME_TYPE_GAMES}`, {
          Obj
        })
      } else {
        this.getFilteredGame(1)
      }
    },
    getGameCategories() {
      this.$store.dispatch(`${GAME_CATEGORIES}`)
    },
    getGameJackpot() {
      if (this.storageRngJackpot == null) {
        this.$store.dispatch(`${GAME_JACKPOT_LIST}`)
      } else {
        this.generateJackpot()
      }
    },
    generateJackpot() {
      let amount = 0
      if (this.storageRngJackpot == null) {
        this.jackpots.forEach(jackpot => {
          amount += parseInt(jackpot.jackpotAmount)
        })
      } else {
        amount = this.storageRngJackpot
      }
      if (amount < SHARED.MIN_JACKPOT_VALUE) amount = parseInt(amount * (SHARED.MIN_JACKPOT_VALUE / amount + 17.37))
      setInterval(this.incrementalJackport, 7777)
      this.jackpotAmountInDigit = amount
      uiHelper.setLocalStorage(`${CACHE_KEY.JACKPOT}_${uiHelper.getCurrency()}`, this.jackpotAmountInDigit, 30)
      this.jackpotAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .split('')
    },
    incrementalJackport() {
      let randomIncreamentalNumber = Math.floor(Math.random() * 4) + 1
      this.jackpotAmountInDigit += randomIncreamentalNumber
      uiHelper.setLocalStorage(`${CACHE_KEY.JACKPOT}_${uiHelper.getCurrency()}`, this.jackpotAmountInDigit, 30)
      this.jackpotAmount = this.jackpotAmountInDigit
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .split('')
    },
    getGameName(gameLocale) {
      try {
        return gameLocale.find(x => x.language == this.currentLanguage).name
      } catch (error) {
        return gameLocale.find(x => x.language == 'en').name
      }
    },
    getFilteredGame(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      let d = this.storageRngGames
      if (this.searchValue != null && this.searchValue != '') {
        if (this.searchValue.name != '' && this.searchValue.name != null) {
          let filtered = this.searchValue.name
          let p = _.filter(d, function(obj) {
            return obj.locale[0].name.toLowerCase().indexOf(filtered.toLowerCase()) > -1
          })
          d = p
        }
      } else {
        // if (gameCetegory != 'all') {
        //     let p = this.storageRngGames.filter(function (el) {
        //         return el.category.filter(x => x.name.toLowerCase() == gameCetegory).length > 0
        //     })
        //     d = p
        // }
        // if (this.searchCriteria.provider != 'all' && this.searchCriteria.provider != '' && this.searchCriteria.provider != undefined) {
        //     let p = d.filter(x => x.provider == this.searchCriteria.provider)
        //     d = p
        // }

        const selectedProvider = []
        for (const [provider, isSelected] of Object.entries(this.selectedProviderGroup)) {
          if (isSelected) {
            selectedProvider.push(provider)
          }
        }
        d = this.storageRngGames.filter(x => selectedProvider.includes(x.provider))

        const selectedCategories = []
        for (const [category, isSelected] of Object.entries(this.selectedCategoryGroup)) {
          if (isSelected) {
            selectedCategories.push(category)
          }
        }

        d = d.filter(function(el) {
          return (
            el.category.filter(x => {
              return selectedCategories.includes(x.name.toLowerCase())
            }).length > 0
          )
        })
      }

      d.forEach(x => {
        this.searchableGameList.push({
          provider: x.provider,
          id: x.id,
          name: x.locale[0].name,
          gamecode: x.code,
          type: x.type
        })
      })
      if (this.$vuetify.breakpoint.xsOnly) {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize, this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.mobilePageSize)
      } else {
        this.gameList = d.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
        this.pagination.totalPage = Math.ceil(d.length / this.searchCriteria.pageSize)
      }
    },
    changeGroup() {
      this.getFilteredGame(1)
    },
    changeGameCategory(gameCetegory) {
      this.gameFilterSheet = false
      this.selectedCategory = gameCetegory
      this.getFilteredGame(1)
      this.pagination.currentPage = 1
    },
    changePage(targetPage) {
      this.getFilteredGame(targetPage)
    },
    proceedPTQuickTransfer(gameInfo) {
      this.$eventHub.$emit('open-quick-transfer-dialog', gameInfo)
    },
    directLaunchGame(gameInfo, isDemo) {
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: gameInfo.provider,
          type: gameInfo.type
        },
        query: {
          id: gameInfo.id,
          code: gameInfo.code,
          demo: isDemo ? 1 : 0
        }
      })
      try {
        this.$ga.event('Game', `${isDemo ? 'Demo' : 'Real'} Play`, `${gameInfo.provider.toUpperCase()} - Slot - ${gameInfo.locale.find(x => x.language == 'en').name}`, gameInfo.id)
      } catch (err) {
        console.log(err)
      }
      this.semiTransfer(gameInfo).then(() => {
        uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1440, 810)
      })
    },
    launchGame(gameInfo, isDemo) {
      if (!isDemo && !this.isLoggedIn) {
        this.$parent.openLoginDialog()
      } else {
        // if (gameInfo.provider == 'pt') {
        //   let ptWalletArr = _.filter(this.wallet.wallets, (el) => {
        //     return el.walletCode == gameInfo.provider.toUpperCase();
        //   })
        //   let ptWallet = _.first(ptWalletArr)
        //   if (ptWallet != null) {
        //     if (parseFloat(ptWallet.balance) < 5) {
        //       this.proceedPTQuickTransfer(gameInfo)
        //     } else {
        //       this.directLaunchGame(gameInfo, isDemo);
        //     }
        //   }
        // } else if (gameInfo.provider != 'pt' && gameInfo.provider != "xe88" && gameInfo.provider != "kiss918" && gameInfo.provider != "mega888" && gameInfo.provider != "pussy888" && gameInfo.provider != "kisskaya") {
        //   let mainWalletArr = _.filter(this.wallet.wallets, (el) => {
        //     return el.walletCode == "MAIN";
        //   })
        //   let mainWallet = _.first(mainWalletArr)
        //   if (mainWallet != null) {
        //     if (parseFloat(mainWallet.balance) < 5) {
        //       this.proceedPTQuickTransfer(gameInfo)
        //     } else {
        //       this.directLaunchGame(gameInfo, isDemo);
        //     }
        //   }
        // } else {
        this.directLaunchGame(gameInfo, isDemo)
        //}
      }
    },
    semiTransfer(gameInfo) {
      let obj = {
        to_wallet: gameInfo.provider
      }
      return this.$store.dispatch(`${SEMI_TRANSFER}`, {
        obj
      })
    },
    goToVendor(providerCode) {
      this.gameFilterSheet = false
      // if (!this.isLoggedIn && this.isTransferWallet) {
      //     this.$parent.openLoginDialog()
      // } else {
      //
      // }
      this.$router.push({
        name: ROUTE_NAME.SLOT_BY_PROVIDER,
        params: {
          provider: providerCode
        }
      })
    },
    checkClassCategory(gameCategory) {
      let isHot = _.findIndex(gameCategory, ['name', 'Hot']) >= 0
      let isNew = _.findIndex(gameCategory, ['name', 'New']) >= 0
      if (this.selectedCategory != 'hot' && this.selectedCategory != 'new') {
        if (isNew) {
          return 'icon_position_new'
        } else if (isHot) {
          return 'icon_position_hot'
        } else {
          return ''
        }
      } else if (this.selectedCategory == 'hot' && isHot) {
        return 'icon_position_hot'
      } else if (this.selectedCategory == 'new' && isNew) {
        return 'icon_position_new'
      } else {
        return ''
      }
    },
    checkCategory(gameCategory) {
      let isHot = _.findIndex(gameCategory, ['name', 'Hot']) >= 0
      let isNew = _.findIndex(gameCategory, ['name', 'New']) >= 0
      let newIcon = '/static/svg/game-icon-new.svg'
      let hotIcon = '/static/svg/game-icon-hot.svg'

      if (this.$vuetify.breakpoint.smAndDown) {
        newIcon = '/static/svg/game-icon-new-mobile.svg'
        hotIcon = '/static/svg/game-icon-hot-mobile.svg'
      }

      if (this.selectedCategory != 'hot' && this.selectedCategory != 'new') {
        if (isNew) {
          //return format('{0}/category_icons/New.svg', this.mediaUrl);
          return newIcon
        } else if (isHot) {
          //return format('{0}/category_icons/Hot.svg', this.mediaUrl);
          return hotIcon
        } else {
          return null
        }
      } else if (this.selectedCategory == 'hot' && isHot) {
        return hotIcon
      } else if (this.selectedCategory == 'new' && isNew) {
        return newIcon
      } else {
        return null
      }
    },
    hoverPlayButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/play_btn_hover.svg'
      } else {
        element.src = '/static/svg/play_btn.svg'
      }
    },
    hoverDemoButton(element, mouseOver) {
      if (mouseOver) {
        element.src = '/static/svg/demo_btn_hover.svg'
      } else {
        element.src = '/static/svg/demo_btn.svg'
      }
    }
  }
}
</script>

<style lang="scss">
.slot-container {
  .banner {
    max-height: 360px;
  }

  .search-container {
    background: #ffffff;

    .search-wrapper {
      max-width: 1240px;
      margin: auto;

      .search_game {
        display: flex;
        align-items: center;

        &.v-text-field--filled > .v-input__control > .v-input__slot {
          min-height: 35px;
          max-width: 240px;
          margin: 12px 0px;
        }
      }
    }
  }

  .game-vendor-container {
    white-space: nowrap;
    background-image: linear-gradient(0, #4F4ABC, #1F2B97 100%);
    color: var(--v-text_color);
    text-transform: capitalize;

    .game-vendor-wrapper {
      position: relative;
      max-width: 1240px;
      margin: auto;

      .more-filter {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 12px;
        height: 40px;
        border-radius: 5px;
        background-image: linear-gradient(0, #000000, #1F2B97 100%);;
        color: var(--v-secondary-base);
        cursor: pointer;
        transition: background-position 0.3s ease-in, text-shadow 0.3s ease-in-out 0.1s;
        background-size: 100% 400%;
        background-position: 0 0;
        box-shadow: 0px 3px 15px #111;
        z-index: 1;
      }

      .more-filter:hover {
        background-position: 0 25%;
        text-shadow: 0 1px 3px rgb(0 0 0 / 50%);
      }

      .vendor-list-item.slot-product {
        display: inline-block;
        margin-left: 12px;
        //min-width: 150px;

        .v-input {
          margin: 0px;
          padding: 8px;
        }

        .v-input--is-label-active {
          .mdi-checkbox-marked {
            color: var(--v-secondary-base) !important;
            caret-color: var(--v-secondary-base) !important;
          }
        }

        .v-btn {
          background: transparent;
        }
      }

      .game-provider {
        .v-item--active {
          .v-icon {
            color: var(--v-secondary-color) !important;
          }
        }
      }

      .game-list-container {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        background-image: transparent;
        color: var(--v-text_color);
      }

      .game-list-container:first-child {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5), 0px 3px 10px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .games-card-container {
    //background-color: #4e4e4e;
    background-color: var(--v-background-lighten1);

    .games-card-wrapper {
      max-width: 1240px;
      margin: 12px auto;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: space-evenly;
      align-items: center;

      .badge-img {
        border-radius: 50%;
      }

      .slot-game-badge {
        z-index: 1;
      }

      .slot-game-card {
        position: relative;
        width: 232px;
        height: 160px;
        //background-color: rgba(20, 128, 94, 0.4);

        .slot-game-image-box {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

        .slot-game-button-container {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 0;

          .slot-game-button-box {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            //transform: scaleY(100);
            //transform: translateY(-100%);
            //transform-origin: bottom;
            transition: transform 1s ease-in;

            .slot-game-demo-button {
              background: linear-gradient(to right, #e32131 0%, #e63846 15%, #e63846 30%, #e32131 55%, #e32131 100%);
              background-position: 50% 0;
              background-size: 200%;
              box-shadow: 0 5px 10px 0 rgb(140 18 28 / 10%), inset 0 0 3px 0 #e63846;
              text-transform: capitalize;
              min-width: 120px;
            }
          }
        }

        &.isMaintenance {
          .slot-game-button-container {
            opacity: 1;
          }
        }

        .slot-game-button-container:hover {
          opacity: 1;
          //transform: translateY(100%);
          //transform: scaleY(1);
          background-color: rgba(0, 0, 0, 0.65);
          box-shadow: inset 0px 0px 1px 1px var(--v-primary-base);
        }

        .slot-game-name-box {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 5px;
          width: 100%;
          min-height: 24px;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          background-color: rgba(6, 12, 6, 0.5);
          box-shadow: 5px 5px 10px rgba(20, 128, 94, 0.7) inset;
          color: white;
          //background-image: linear-gradient(0, #14805e, #000000 100%);
          //background-image: url("/static/btn-light.png");
          //mask-image: image(url("/static/btn-light.png"), blue);
          //mask-image: url("/static/btn-light.png");
          //mask-repeat: no-repeat;
          //mask-position: center;
          //background-color: var(--v-primary-base);
          //background: url("/static/image/navigator/nav-light.png") no-repeat center bottom;
          //background-size: 100% 50%;
          //box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

@media (max-width: 599px) {
  .slot-container {
    .games-card-wrapper {
      display: flex !important;
      justify-content: space-evenly !important;

      .slot-game-card {
        width: 160px !important;
        height: 115px !important;
      }

      .slot-game-name-box {
        background-color: var(--v-base-base);
      }
    }
  }
}

.under-maintenance-text {
  font-size: 16px;
}

.game-page-card-menu {
  max-height: 80px;
  border-radius: 20px !important;

  .game-menu-title {
    font-size: 24px;
    text-transform: capitalize;

    &:hover {
      color: #0c2aab;
    }
  }

  .page-navigator-item {
    &.v-btn--active {
      &::before {
        opacity: 0;
      }

      .underline_bar {
        opacity: 1;
      }
    }
  }
}

.vendor_small_icon {
  width: 40px;
  height: 40px;
  right: 1%;
  position: absolute;
  top: 1%;
}

.game_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.icon_position_hot {
  background-position: 0px 8px;
  left: -7px;
  top: 8px;
  width: 100px;
  height: 50px;

  &.isMobile {
    width: 75px;
    height: 35px;
  }
}

.icon_position_new {
  background-position: 0px 8px;
  left: -7px;
  top: -7px;
  width: 100px;
  height: 100px;

  &.isMobile {
    left: -4px;
    top: 0px;
    width: 50px;
    height: 40px;
  }
}

.buttonGold {
  background-image: linear-gradient(to right, var(--v-buttonGold_primary-base), var(--v-buttonGold_secondary-base));
}

.v-chip.v-size--default.provider_chips {
  height: 20px;
}

.game-filter-sheet-header {
  background-image: linear-gradient(to right, var(--v-primary-base), var(--v-primary_3-base));
  padding: 15px 0;
  position: relative;

  .game-filter-sheet-close-icon {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 35px;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
}

.game-filter-sheet-body {
  position: relative;
  overflow: scroll;
  height: 100%;

  .game-filter-sheet-body-content {
    padding: 15px;

    .game-filter-vendor-type-item-box {
      padding-top: 25px;

      .game-filter-vendor-type-item {
        font-size: 20px;
        padding: 10px;
        display: inline-block;

        p {
          font-size: 18px;
          border-radius: 5px;
          padding: 8px 25px;
          border: 3px solid var(--v-primary_2-base);
          margin: 0px !important;

          &.active {
            background-color: var(--v-primary_2-base);
          }
        }
      }
    }

    .game-filter-sheet-body-content-text {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0 !important;
    }
  }
}

.game-filter-display {
  padding: 10px 15px;
}

.game-filter-display-text {
  font-size: 14px;
  margin-bottom: 0 !important;
}

.game-filter-button {
  background: linear-gradient(180deg, #273270 0%, #1c244f 100%);
  border-radius: 5px;
  width: 90px !important;
  font-weight: bold;
  min-width: 90px !important;
  padding: 5px 0px !important;

  .v-icon {
    font-size: 28px;
    padding: 0px;
  }
}

.jackpot-container {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
}

.jackpot-title {
  font-size: 70px;
  font-weight: bold;
  color: white;
  text-shadow: 3px 3px 7px #000000;
}

.jackpot-char-box {
  font-size: 100px;
  color: white;
  font-weight: bold;

  &.num {
    margin: 0px 5px;
    width: 100px;
    border-radius: 10px;
    border: 5px solid;
    border-color: var(--v-primary_2-base);
    background-image: linear-gradient(to bottom, var(--v-primary-base), var(--v-base-base));
    display: inline-block;
    text-align: center;
  }
}

.game-filter-display {
  display: none;
}

.vendor-list-item.slot-product {
  .v-label {
    color: white !important;
  }
}

.search_game .v-label {
  top: 8px !important;
}
</style>
